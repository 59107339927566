var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ValidationObserver", {
    ref: "form",
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ passes }) {
            return [
              _c(
                "div",
                [
                  _c(
                    "b-row",
                    { staticClass: "m-1" },
                    [
                      _c("b-col", { attrs: { cols: "2" } }, [
                        _c("h1", [_vm._v("Validate Claim")]),
                      ]),
                      _c("b-col", [
                        _c(
                          "span",
                          [
                            _c("amd-pending-changes", {
                              attrs: {
                                "visit-id": _vm.formData.id,
                                status: _vm.ClaimChangesStatus.Approved,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card",
                [
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-center" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticStyle: { width: "30%" },
                            attrs: {
                              label: "Claim Number",
                              "label-for": "claimNumber",
                            },
                          },
                          [
                            _c("validation-provider", {
                              attrs: { name: "claimNumber", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                id: "claimNumber",
                                                type: "text",
                                                size: "sm",
                                              },
                                              model: {
                                                value: _vm.claimNumber,
                                                callback: function ($$v) {
                                                  _vm.claimNumber = $$v
                                                },
                                                expression: "claimNumber",
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      size: "sm",
                                                      variant: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.validateClaim(
                                                          _vm.claimNumber
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v("Validate "),
                                                    _vm.isValidating
                                                      ? _c("b-spinner", {
                                                          attrs: {
                                                            small: "",
                                                            type: "grow",
                                                            label: "Spinning",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.scrubResult
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("validation-provider", {
                                    attrs: { name: "patientResponsibility" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c(
                                                "span",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "py-2" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Insurance Company",
                                        "label-for": "insuranceCompany",
                                      },
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "insuranceId",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("infinit-select", {
                                                    attrs: {
                                                      id: "insuranceCompany",
                                                      includeIds: [
                                                        _vm.formData
                                                          .insuranceId,
                                                      ],
                                                      repo: _vm.insuranceRepo,
                                                      label: "name",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formData
                                                          .insuranceId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "insuranceId",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "formData.insuranceId",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Insurance Plan",
                                        "label-for": "insurancePlan",
                                      },
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "insurancePlanId",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("infinit-select", {
                                                    attrs: {
                                                      id: "insurancePlan",
                                                      includeIds: [
                                                        _vm.formData
                                                          .insurancePlanId,
                                                      ],
                                                      repo: _vm.insurancePlanRepo,
                                                      label: "name",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formData
                                                          .insurancePlanId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "insurancePlanId",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "formData.insurancePlanId",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Practice",
                                        "label-for": "practice",
                                      },
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "practiceId",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("infinit-select", {
                                                    attrs: {
                                                      id: "practice",
                                                      includeIds: [
                                                        _vm.formData.practiceId,
                                                      ],
                                                      repo: _vm.practiceRepo,
                                                      label: "name",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formData.practiceId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "practiceId",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "formData.practiceId",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Location",
                                        "label-for": "location",
                                      },
                                    },
                                    [
                                      _c("infinit-select", {
                                        attrs: {
                                          id: "location",
                                          label: "code",
                                          repo: _vm.locationRepo,
                                          filter: {
                                            PracticeId: _vm.formData.practiceId,
                                          },
                                          "include-ids": [
                                            _vm.formData.locationId,
                                          ],
                                          disabled: !_vm.formData.practiceId,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selected-option",
                                              fn: function ({ name, code }) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(name) +
                                                        " (" +
                                                        _vm._s(code) +
                                                        ")"
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.formData.locationId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "locationId",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "formData.locationId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Profile",
                                        "label-for": "profile",
                                      },
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "profileId",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("infinit-select", {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.formData
                                                          .practiceId,
                                                      id: "profile",
                                                      includeIds: [
                                                        _vm.formData.profileId,
                                                      ],
                                                      filter: {
                                                        practiceId:
                                                          _vm.formData
                                                            .practiceId,
                                                      },
                                                      repo: _vm.profileRepo,
                                                      label: "name",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formData.profileId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "profileId",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "formData.profileId",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Provider Profile",
                                        "label-for": "providerProfile",
                                      },
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "providerProfileId",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("infinit-select", {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.formData
                                                          .practiceId,
                                                      id: "providerProfile",
                                                      includeIds: [
                                                        _vm.formData
                                                          .providerProfileId,
                                                      ],
                                                      filter: {
                                                        practiceId:
                                                          _vm.formData
                                                            .practiceId,
                                                      },
                                                      repo: _vm.providerProfileRepo,
                                                      label: "code",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formData
                                                          .providerProfileId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "providerProfileId",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "formData.providerProfileId",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("h4", [_vm._v("Patient info")]),
                          _c(
                            "b-row",
                            { staticClass: "py-2" },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Patient",
                                        "label-for": "patient",
                                      },
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "patientId",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("infinit-select", {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.formData
                                                          .practiceId,
                                                      id: "patient",
                                                      includeIds: [
                                                        _vm.formData.patientId,
                                                      ],
                                                      filter: {
                                                        practiceId:
                                                          _vm.formData
                                                            .practiceId,
                                                      },
                                                      repo: _vm.patientRepo,
                                                      label: "name",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formData.patientId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "patientId",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "formData.patientId",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("b-form-group", {
                                    attrs: { label: "Gender" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ ariaDescribedby }) {
                                            return [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "gender",
                                                  rules: "required",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        errors,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "b-form-radio-group",
                                                            {
                                                              attrs: {
                                                                options: [
                                                                  "Male",
                                                                  "Female",
                                                                ],
                                                                "aria-describedby":
                                                                  ariaDescribedby,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.formData
                                                                    .gender,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.formData,
                                                                      "gender",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "formData.gender",
                                                              },
                                                            }
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("h4", [_vm._v("Visit info")]),
                          _c(
                            "b-row",
                            { staticClass: "py-2" },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Visit Date",
                                        "label-for": "visitDate",
                                      },
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "visitDate",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("b-form-datepicker", {
                                                    attrs: {
                                                      id: "visitDate",
                                                      type: "date",
                                                      size: "sm",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formData.visitDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "visitDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formData.visitDate",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "POS Code",
                                        "label-for": "posCode",
                                      },
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "posCodeId",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("infinit-select", {
                                                    attrs: {
                                                      id: "provider",
                                                      includeIds: [
                                                        _vm.formData.posCodeId,
                                                      ],
                                                      repo: _vm.posRepo,
                                                      label: "code",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formData.posCodeId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "posCodeId",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "formData.posCodeId",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Hospital Admission Date",
                                        "label-for": "hospitalAdmissionDate",
                                      },
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "hospitalAdmissionDate",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("b-form-datepicker", {
                                                    attrs: {
                                                      id: "hospitalAdmissionDate",
                                                      type: "date",
                                                      size: "sm",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formData
                                                          .hospitalAdmissionDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "hospitalAdmissionDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formData.hospitalAdmissionDate",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Date Last Seen",
                                        "label-for": "dateLastSeen",
                                      },
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "dateLastSeen",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("b-form-datepicker", {
                                                    attrs: {
                                                      id: "dateLastSeen",
                                                      type: "date",
                                                      size: "sm",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formData
                                                          .dateLastSeen,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "dateLastSeen",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formData.dateLastSeen",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("h4", [_vm._v("Authorization info")]),
                          _c(
                            "b-row",
                            { staticClass: "py-2" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "hasAuthorization",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  staticClass: "mb-1",
                                                  attrs: {
                                                    id: "hasAuthorization",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formData
                                                        .hasAuthorization,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "hasAuthorization",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.hasAuthorization",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " Patient has authorization "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "hasReferringDoctor",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    id: "hasReferringDoctor",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formData
                                                        .hasReferringDoctor,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "hasReferringDoctor",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.hasReferringDoctor",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " Has referring doctor "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("h4", [_vm._v("Diagnosis")]),
                          _c(
                            "b-row",
                            { staticClass: "py-1" },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Diagnosis",
                                        "label-for": "diagnosis",
                                      },
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "diagnosisIds",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("infinit-select", {
                                                    ref: "diag",
                                                    attrs: {
                                                      id: "diagnosis",
                                                      multiple: true,
                                                      includeIds:
                                                        _vm.formData
                                                          .diagnosisIds,
                                                      repo: _vm.diagRepo,
                                                      label: "code",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formData
                                                          .diagnosisIds,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "diagnosisIds",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formData.diagnosisIds",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(errors[0]) + " "
                                                      ),
                                                    ]
                                                  ),
                                                  errors[0]
                                                    ? _c(
                                                        "tooltip",
                                                        {
                                                          attrs: {
                                                            classes:
                                                              "drop-theme-arrows",
                                                            position:
                                                              "bottom left",
                                                            openOn: "click",
                                                            header:
                                                              "Issue Encountered",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "small",
                                                            {
                                                              attrs: {
                                                                slot: "drop-trigger",
                                                              },
                                                              slot: "drop-trigger",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticStyle: {
                                                                    "text-decoration":
                                                                      "underline",
                                                                  },
                                                                  attrs: {
                                                                    href: "#",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Details"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              attrs: {
                                                                slot: "drop-content",
                                                              },
                                                              slot: "drop-content",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " errors[0] "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("validation-provider", {
                                    attrs: { name: "procedureSort" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c(
                                                "span",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("h4", { staticClass: "mt-2" }, [
                            _vm._v("Procedures for this claim"),
                          ]),
                          _vm._l(
                            _vm.formData.procedures,
                            function (procedure, index) {
                              return [
                                _c(
                                  "div",
                                  [
                                    _c("Procedure", {
                                      attrs: {
                                        "procedure-code":
                                          procedure.procedureCodeId,
                                        "date-of-service":
                                          procedure.dateOfService,
                                        "proc-units": procedure.units,
                                        amount: procedure.chargeAmount,
                                        "place-of-service":
                                          procedure.placeOfServiceId,
                                        modifiers: procedure.modifierIds,
                                        "diag-pointers": procedure.diagPointers,
                                        "diagnosis-pointers-options": _vm.diags
                                          ? _vm.diags.insuranceOptions.filter(
                                              (diag) =>
                                                _vm.formData.diagnosisIds.includes(
                                                  diag.id
                                                )
                                            )
                                          : [],
                                        index: index,
                                      },
                                      on: {
                                        "update:procedureCode": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            procedure,
                                            "procedureCodeId",
                                            $event
                                          )
                                        },
                                        "update:procedure-code": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            procedure,
                                            "procedureCodeId",
                                            $event
                                          )
                                        },
                                        "update:dateOfService": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            procedure,
                                            "dateOfService",
                                            $event
                                          )
                                        },
                                        "update:date-of-service": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            procedure,
                                            "dateOfService",
                                            $event
                                          )
                                        },
                                        "update:procUnits": function ($event) {
                                          return _vm.$set(
                                            procedure,
                                            "units",
                                            $event
                                          )
                                        },
                                        "update:proc-units": function ($event) {
                                          return _vm.$set(
                                            procedure,
                                            "units",
                                            $event
                                          )
                                        },
                                        "update:amount": function ($event) {
                                          return _vm.$set(
                                            procedure,
                                            "chargeAmount",
                                            $event
                                          )
                                        },
                                        "update:placeOfService": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            procedure,
                                            "placeOfServiceId",
                                            $event
                                          )
                                        },
                                        "update:place-of-service": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            procedure,
                                            "placeOfServiceId",
                                            $event
                                          )
                                        },
                                        "update:modifiers": function ($event) {
                                          return _vm.$set(
                                            procedure,
                                            "modifierIds",
                                            $event
                                          )
                                        },
                                        "update:diagPointers": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            procedure,
                                            "diagPointers",
                                            $event
                                          )
                                        },
                                        "update:diag-pointers": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            procedure,
                                            "diagPointers",
                                            $event
                                          )
                                        },
                                        remove: function ($event) {
                                          return _vm.removeProcedure(index)
                                        },
                                      },
                                    }),
                                    _vm._l(
                                      _vm.procedureErrors[index],
                                      function (error, indexc) {
                                        return [
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "b-alert",
                                                    {
                                                      attrs: {
                                                        variant: "danger",
                                                        show: "",
                                                      },
                                                    },
                                                    [
                                                      _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(error) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      }
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _vm._l(
                                          _vm.procedureWarnings[index],
                                          function (warning, indexc) {
                                            return [
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "b-alert",
                                                    {
                                                      attrs: {
                                                        variant: "warning",
                                                        show: "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(warning) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            }
                          ),
                          _c(
                            "b-row",
                            { staticClass: "py-2" },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        size: "md",
                                        variant: "primary",
                                        pill: "",
                                      },
                                      on: { click: _vm.addProcedure },
                                    },
                                    [_vm._v("+ Add Procedure")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.scrubResultId
                    ? _c("notes-popover", {
                        attrs: { filter: { scrubResultId: _vm.scrubResultId } },
                      })
                    : _vm._e(),
                  _vm._t("footer1", function () {
                    return [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "9" } }),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-row",
                                [
                                  _c("b-col"),
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            size: "md",
                                            variant: "primary",
                                            block: "",
                                          },
                                          on: { click: _vm.submitForm },
                                        },
                                        [
                                          _c("b-spinner", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.isLoading,
                                                expression: "isLoading",
                                              },
                                            ],
                                            attrs: { small: "" },
                                          }),
                                          _vm._v(" Submit "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }