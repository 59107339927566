var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("form-component", {
    attrs: { copyData: _vm.procedureCodeCopy },
    on: {
      "update:copyData": function ($event) {
        _vm.procedureCodeCopy = $event
      },
      "update:copy-data": function ($event) {
        _vm.procedureCodeCopy = $event
      },
      save: _vm.save,
      "on-copy": _vm.onCopy,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "4", sm: "6", xs: "12" } },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Procedure Code" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Procedure Code",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: "",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.procedureCodeCopy.procCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procedureCodeCopy,
                                                  "procCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procedureCodeCopy.procCode",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "RVU Code" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "RVU Code",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: "",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.procedureCodeCopy.rvuCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procedureCodeCopy,
                                                  "rvuCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procedureCodeCopy.rvuCode",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Procedure Type" } },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "Procedure Type", rules: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("infinit-select", {
                                            attrs: {
                                              repo: _vm.procTypesRepo,
                                              "include-ids": [
                                                _vm.procedureCodeCopy
                                                  .procTypeId,
                                              ],
                                              label: "procType",
                                            },
                                            model: {
                                              value:
                                                _vm.procedureCodeCopy
                                                  .procTypeId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procedureCodeCopy,
                                                  "procTypeId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procedureCodeCopy.procTypeId",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Add-on?" } },
                              [
                                _c("validation-provider", {
                                  staticClass: "demo-inline-spacing",
                                  attrs: { name: "Add-on", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "b-form-radio",
                                            {
                                              staticClass: "pt-0 mt-0",
                                              attrs: {
                                                name: "Add-on",
                                                value: true,
                                                required: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.procedureCodeCopy.isAddOn,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.procedureCodeCopy,
                                                    "isAddOn",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "procedureCodeCopy.isAddOn",
                                              },
                                            },
                                            [_vm._v(" Yes ")]
                                          ),
                                          _c(
                                            "b-form-radio",
                                            {
                                              staticClass: "pt-0 mt-0",
                                              attrs: {
                                                name: "Add-on",
                                                value: false,
                                                required: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.procedureCodeCopy.isAddOn,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.procedureCodeCopy,
                                                    "isAddOn",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "procedureCodeCopy.isAddOn",
                                              },
                                            },
                                            [_vm._v(" No ")]
                                          ),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "From procedure code" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "From procedure code",
                                    rules:
                                      _vm.procedureCodeCopy.isAddOn == true
                                        ? "required"
                                        : "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("infinit-select", {
                                            attrs: {
                                              repo: _vm.procCodesRepo,
                                              "include-ids": _vm.addOns,
                                              multiple: "",
                                              label: "procCode",
                                              disabled:
                                                _vm.procedureCodeCopy.isAddOn ==
                                                  null ||
                                                _vm.procedureCodeCopy.isAddOn ==
                                                  false,
                                            },
                                            on: {
                                              "option:selecting":
                                                _vm.onSelected,
                                              "option:deselected":
                                                _vm.onDeselected,
                                            },
                                            model: {
                                              value: _vm.addOns,
                                              callback: function ($$v) {
                                                _vm.addOns = $$v
                                              },
                                              expression: "addOns",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Is initial consultation?" } },
                              [
                                _c("validation-provider", {
                                  staticClass: "demo-inline-spacing",
                                  attrs: {
                                    name: "Initial Consultation",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "b-form-radio",
                                            {
                                              staticClass: "pt-0 mt-0",
                                              attrs: {
                                                name: "Initial Consultation",
                                                value: "true",
                                                required: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.procedureCodeCopy
                                                    .isInitialConsultation,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.procedureCodeCopy,
                                                    "isInitialConsultation",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "procedureCodeCopy.isInitialConsultation",
                                              },
                                            },
                                            [_vm._v(" Yes ")]
                                          ),
                                          _c(
                                            "b-form-radio",
                                            {
                                              staticClass: "pt-0 mt-0",
                                              attrs: {
                                                name: "Initial Consultation",
                                                value: "false",
                                                required: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.procedureCodeCopy
                                                    .isInitialConsultation,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.procedureCodeCopy,
                                                    "isInitialConsultation",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "procedureCodeCopy.isInitialConsultation",
                                              },
                                            },
                                            [_vm._v(" No ")]
                                          ),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                directives: [
                                  { name: "b-tooltip", rawName: "v-b-tooltip" },
                                ],
                                attrs: {
                                  title:
                                    "set this if this procedure is not a real procedure code but was added for some reason (e.g. like #int) usually its start with # sign",
                                  label: "Is non procedure code?",
                                },
                              },
                              [
                                _c("validation-provider", {
                                  staticClass: "demo-inline-spacing",
                                  attrs: {
                                    name: "Non Procedure Code",
                                    rules: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-radio-group", {
                                            staticClass: "pt-0 mt-0",
                                            attrs: {
                                              options: [
                                                { text: "Yes", value: true },
                                                { text: "No", value: false },
                                              ],
                                              name: "Non Procedure Code",
                                            },
                                            model: {
                                              value:
                                                _vm.procedureCodeCopy
                                                  .isNonCptCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procedureCodeCopy,
                                                  "isNonCptCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procedureCodeCopy.isNonCptCode",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                directives: [
                                  { name: "b-tooltip", rawName: "v-b-tooltip" },
                                ],
                                attrs: {
                                  title:
                                    "set this to flag this  procedure in the scrubber for manual review",
                                  label: "Requires manual review?",
                                },
                              },
                              [
                                _c("validation-provider", {
                                  staticClass: "demo-inline-spacing",
                                  attrs: {
                                    name: "Requires manual review",
                                    rules: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-radio-group", {
                                            staticClass: "pt-0 mt-0",
                                            attrs: {
                                              options: [
                                                { text: "Yes", value: true },
                                                { text: "No", value: false },
                                              ],
                                              name: "Requires manual review",
                                            },
                                            model: {
                                              value:
                                                _vm.procedureCodeCopy
                                                  .requiresManualReview,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procedureCodeCopy,
                                                  "requiresManualReview",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procedureCodeCopy.requiresManualReview",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "4", sm: "6", xs: "12" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "Description" } },
                      [
                        _c("validation-provider", {
                          attrs: { name: "Description", rules: "required" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("b-form-textarea", {
                                    attrs: {
                                      rows: "4",
                                      placeholder: "",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.procedureCodeCopy.description,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.procedureCodeCopy,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "procedureCodeCopy.description",
                                    },
                                  }),
                                  _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(_vm._s(errors[0])),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.formData,
      callback: function ($$v) {
        _vm.formData = $$v
      },
      expression: "formData",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }