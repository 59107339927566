/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    AmdUpdateChangeDto,
    AmdUpdateChangeDtoListResponseModel,
    AmdUpdateChangeDtoModelResponse, ClaimChangesStatus,
    GeneralStatus,
    SortType,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class AmdUpdateChange<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags AmdUpdateChange
   * @name LoadList
   * @request GET:/AmdUpdateChange/Load
   * @secure
   */
  loadList = (
    query?: {
      /** @format int64 */
      VisitId?: number;
      /** @format int32 */
      Take?: number;
      /** @format int32 */
      Skip?: number;
      IncludeIds?: number[];
      orderByField?: string;
      orderByType?: SortType;
      SearchTerm?: string;
      ColumnFilters?: string;
      Status?: ClaimChangesStatus;
      /** @format int64 */
      PracticeId?: number;
      ForDropdown?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<AmdUpdateChangeDtoListResponseModel, any>({
      path: `/AmdUpdateChange/Load`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AmdUpdateChange
   * @name PostAmdUpdateChange
   * @request POST:/AmdUpdateChange/Add
   * @secure
   */
  postAmdUpdateChange = (data: AmdUpdateChangeDto, params: RequestParams = {}) =>
    this.request<AmdUpdateChangeDtoModelResponse, any>({
      path: `/AmdUpdateChange/Add`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AmdUpdateChange
   * @name GetByIdList
   * @request GET:/AmdUpdateChange/GetById
   * @secure
   */
  getByIdList = (
    query?: {
      /** @format int64 */
      id?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<AmdUpdateChangeDtoModelResponse, any>({
      path: `/AmdUpdateChange/GetById`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AmdUpdateChange
   * @name DeleteDelete
   * @request DELETE:/AmdUpdateChange/Delete
   * @secure
   */
  deleteDelete = (
    query?: {
      /** @format int64 */
      id?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<AmdUpdateChangeDtoModelResponse, any>({
      path: `/AmdUpdateChange/Delete`,
      method: "DELETE",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AmdUpdateChange
   * @name UpdateCreate
   * @request POST:/AmdUpdateChange/Update
   * @secure
   */
  updateCreate = (data: AmdUpdateChangeDto, params: RequestParams = {}) =>
    this.request<AmdUpdateChangeDtoModelResponse, any>({
      path: `/AmdUpdateChange/Update`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
