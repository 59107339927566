var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "py-4" },
        [
          _c(
            "b-col",
            { attrs: { cols: "8" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Procedure Code",
                            "label-for": "procedureCode" + _vm.index,
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "Procedure Code",
                              rules: "required",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("infinit-select", {
                                      attrs: {
                                        id: "procedureCode" + _vm.index,
                                        includeIds: [_vm.cpt],
                                        repo: _vm.cptRepo,
                                        label: "procCode",
                                      },
                                      model: {
                                        value: _vm.cpt,
                                        callback: function ($$v) {
                                          _vm.cpt = $$v
                                        },
                                        expression: "cpt",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Date",
                            "label-for": "date" + _vm.index,
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "Date", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("b-form-datepicker", {
                                      attrs: {
                                        id: "date" + _vm.index,
                                        type: "date",
                                        size: "sm",
                                      },
                                      model: {
                                        value: _vm.date,
                                        callback: function ($$v) {
                                          _vm.date = $$v
                                        },
                                        expression: "date",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "POS Code",
                            "label-for": "posCode" + _vm.index,
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "POS Code", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("infinit-select", {
                                      attrs: {
                                        id: "posCode" + _vm.index,
                                        includeIds: [_vm.pos],
                                        repo: _vm.posRepo,
                                        label: "code",
                                      },
                                      model: {
                                        value: _vm.pos,
                                        callback: function ($$v) {
                                          _vm.pos = _vm._n($$v)
                                        },
                                        expression: "pos",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Modifiers",
                            "label-for": "modifiers" + _vm.index,
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "Modifiers", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("infinit-select", {
                                      attrs: {
                                        id: "modifiers" + _vm.index,
                                        includeIds: _vm.mods,
                                        repo: _vm.modifiersRepo,
                                        label: "code",
                                        multiple: "",
                                      },
                                      model: {
                                        value: _vm.mods,
                                        callback: function ($$v) {
                                          _vm.mods = $$v
                                        },
                                        expression: "mods",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Diagnosis Pointers",
                            "label-for": "diagnosisPointers" + _vm.index,
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "Diagnosis Pointers",
                              rules: "required",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("v-select", {
                                      attrs: {
                                        id: "diagnosisPointers" + _vm.index,
                                        options: _vm.diagnosisPointersOptions,
                                        reduce: (x) => x.id,
                                        label: "code",
                                        multiple: "",
                                      },
                                      model: {
                                        value: _vm.diagnosisPointers,
                                        callback: function ($$v) {
                                          _vm.diagnosisPointers = $$v
                                        },
                                        expression: "diagnosisPointers",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "verticalLine", attrs: { cols: "4" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Charge Amount",
                            "label-for": "chargeAmount" + _vm.index,
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "Charge Amount", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "chargeAmount" + _vm.index,
                                        type: "number",
                                        size: "sm",
                                      },
                                      model: {
                                        value: _vm.chargeAmount,
                                        callback: function ($$v) {
                                          _vm.chargeAmount = _vm._n($$v)
                                        },
                                        expression: "chargeAmount",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Units",
                            "label-for": "units" + _vm.index,
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "Units", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "units" + _vm.index,
                                        type: "number",
                                        size: "sm",
                                      },
                                      model: {
                                        value: _vm.units,
                                        callback: function ($$v) {
                                          _vm.units = _vm._n($$v)
                                        },
                                        expression: "units",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple.2000",
                              value: "rgba(255, 69, 0, 0.5)",
                              expression: "'rgba(255, 69, 0, 0.5)'",
                              modifiers: { 2000: true },
                            },
                          ],
                          staticClass: "my-2",
                          attrs: { variant: "transparent", size: "sm" },
                          on: { click: _vm.remove },
                        },
                        [
                          _c("b-icon-x-circle", {
                            attrs: { scale: "1.5", variant: "danger" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "horizontalLine mx-0" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }